import styled from 'styled-components';
import {Link as GatsbyLink} from 'gatsby';

const blue = '#357EDD';
const darkBlue = '#00449e';

export const Container = styled.div`
  margin: 2rem auto;
  padding: 2rem;
  width: 42rem;
  height: auto;
  border: 1px solid #e8e9f1;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  div {
    margin-top: 2rem;
  }
  p {
    margin: 1rem 0;
    margin-bottom: 2rem;
    text-align: center;
  }
  table {
    border: none;
    width: 70%;
    tr {
      background-color: #ffff;
    }
  }
  @media (max-width: 800px) {
    margin: 2rem 2rem;
    width: auto;
    padding: 2rem 0rem;
  }
  @media (max-width: 600px) {
    table {
      width: 100%;
    }
  }
  @media (max-width: 400px) {
    font-size: 0.8rem;
    margin: 2rem 0rem;
    border: none;
  }
`;
export const LinksContainer = styled.div`
  width:100%
  display:flex;
  justify-content:space-between;
  margin: 3rem 0.5rem;
  @media (max-width: 800px) {
    display:flex;
    justify-content:center;
    flex-direction: column;
    align-self: stretch;
  }
`;
export const StyledLink = styled(GatsbyLink)`
  background-color: ${blue};
  color: #ffff;
  border-radius: 2px;
  border: 2px solid ${blue};
  padding: 0.7rem 0;
  margin: 1.5rem 0.5rem;
  width: 80%;
  text-align: center;
  &:hover {
    background-color: ${darkBlue};
    border: 2px solid ${darkBlue};
  }
  @media (max-width: 800px) {
    margin: 0.5rem;
    padding: 16px 4rem;
    text-align: center;
  }
`;

export const StyledLink2 = styled(GatsbyLink)`
  background-color: ${({variant}) => (variant === 'primary' ? blue : '#fff')};
  color: ${({variant}) => (variant === 'primary' ? '#fff' : blue)};
  border: 2px solid ${blue};
  border-radius: 2px;
  padding: 0.7rem 4rem;
  margin: 1.5rem 0.5rem;
  cursor: pointer;
  text-align: center;
  @media (max-width: 800px) {
    margin: 0.5rem;
  }
  &:hover {
    background-color: ${({variant}) =>
      variant === 'primary' ? darkBlue : '#fff'};
    border: 2px solid ${darkBlue};
    color: ${({variant}) => (variant === 'primary' ? '#fff' : darkBlue)};
  }
`;

export const DataTable = styled.td`
  font-weight: bold;
  @media (max-width: 600px) {
    text-align: left;
    padding: 0.8rem;
  }
`;
export const TitleTable = styled.td`
  color: #50575f;
  @media (max-width: 600px) {
    text-align: right;
    padding: 0.8rem;
  }
`;
export const Line = styled.div`
  width: 90%;
  height: 0;
  margin: 0 0 2rem 0;
  border-top: 1px solid #dfe4eb;
`;
